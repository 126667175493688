export const wordLists = [
  [
    "wandering",
    "scaly",
    "hairy",
    "furry",
    "itching",
    "scratching",
    "spiny",
    "rotten",
    "mouldy",
    "squelchy",
    "stinky",
    "smelly",
    "slimy",
    "muddy",
    "squishy",
    "spotty",
    "orange",
    "faded",
    "soggy",
    "oily",
    "greasy",
    "boring",
    "gelatinous",
    "rancid",
    "disgusting",
    "gassy",
    "oozing",
    "dripping",
    "scabby",
    "bulging",
    "cheesy",
    "waxy",
    "squeaky",
    "bushy"
  ],
  [
    "tree",
    "river",
    "space",
    "field",
    "lake",
    "ocean",
    "sea",
    "wood",
    "forest",
    "desert",
    "hedge",
    "gutter",
    "mud",
    "bog",
    "marsh",
    "chimney",
    "attic",
    "cellar",
    "cave",
    "sewer",
    "slime",
    "toilet",
    "dungeon",
    "garbage",
    "arm pit",
    "pant",
    "fridge",
    "suburban",
    "urban",
    "toe",
    "butt",
    "boob",
    "buttock"
  ],
  [
    "hobbit",
    "toad",
    "cucumber",
    "fudge",
    "slime",
    "mould",
    "stink",
    "turd",
    "smell",
    "tussock",
    "clod",
    "worm",
    "slug",
    "banana",
    "lump",
    "raspberry",
    "fart",
    "trumpet",
    "burp",
    "twit",
    "flump",
    "nugget",
    "cist",
    "spillage",
    "cheese",
    "drip",
    "bogey",
    "stain",
    "scab",
    "wart",
    "plug",
    "hole",
    "wiener",
    "nostril",
    "orifice",
    "log",
    "bunion",
    "dribble",
    "plop",
    "stench",
    "toenail",
  ]
];